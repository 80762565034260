import React from "react"
import { WhiteButton } from "../components/buttons"

const Thanks = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="flex flex-col items-center justify-center rounded w-72 h-32 shadow-lg">
        <p className="subtitle">Thank you for your message.</p>
        <p>I'll get back to you ASAP.</p>
        <div className="w-full flex justify-end pt-4 pr-2">
          <WhiteButton to="/" text="Back" />
        </div>
      </div>
    </div>
  )
}

export default Thanks
